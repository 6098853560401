<template>
  <v-btn
    @click="build"
    :disabled="details?.confirm_status != '1'"
    outlined
    :width="width"
    elevation="0"
    color="primary"
    class="no-transform"
    >Print Receipt</v-btn
  >
</template>

<script>
import { mapGetters } from "vuex";
import { bolddLogo } from "@/store/image";
import pdfMake from "pdfmake/build/pdfmake";
import formatAmount from "@/hooks/formatAmount";
import { bus } from "@/main";
pdfMake.fonts = {
  vt323: {
    normal: "https://fonts.gstatic.com/s/vt323/v8/ITU2YQfM073o1iYK3nSOmQ.ttf",
  },
};
export default {
  name: "receipt",
  props: ["details", "width"],
  data: () => ({}),
  computed: {
    ...mapGetters(["getUserDetails"]),
  },
  methods: {
    build() {
      const docDefinition = {
        content: [
          { text: "***CUSTOMER'S COPY***", style: ["center"] },
          {
            width: 80,
            image: bolddLogo,
            marginTop: 10,
            style: ["center"],
          },
          { text: this.getUserDetails.business_name, style: ["header", "center"], marginTop: 10 },
          { text: this.details?.branch_name, style: ["center"] },

          { text: this.details?.product, style: ["header2", "center"], marginTop: 10 },
          { text: ".....................................", style: "center", marginBottom: 15 },
          {
            columns: [
              { width: "*", text: "TERMINAL" },
              { width: "*", text: this.details?.recipient },
            ],
            columnGap: 10,
            marginBottom: 3,
          },
          {
            columns: [
              { width: "*", text: "DATE/TIME" },
              { width: "*", text: this.details?.dated },
            ],
            columnGap: 10,
            marginBottom: 3,
          },
          {
            columns: [
              { width: "*", text: "BANK" },
              { width: "*", text: `${this.details?.senderbank}|${this.details?.senderacct}` },
            ],
            columnGap: 10,
            marginBottom: 3,
          },
          {
            columns: [
              { width: "*", text: "CUSTOMER" },
              { width: "*", text: this.details?.acctname },
            ],
            columnGap: 10,
            marginBottom: 3,
          },
          {
            columns: [
              { width: "*", text: "TRANSACTION REF" },
              { width: "*", text: this.details?.txref },
            ],
            columnGap: 10,
          },

          {
            text: `${this.details?.currency} ${formatAmount(this.details?.amount)}`,
            style: ["header3", "center"],
            marginTop: 15,
          },
          { text: `${this.details?.statustext}`.toLocaleUpperCase(), style: ["header2", "center"], marginTop: 5 },
          {
            columns: [
              { width: "*", text: "RESPONSE CODE" },
              { width: "*", text: `0${this.details.statuscode}` },
            ],
            columnGap: 10,
            marginTop: 15,
            marginBottom: 3,
          },
          {
            columns: [
              { width: "*", text: "MESSAGE" },
              { width: "*", text: `${this.details?.statustext}` },
            ],
            columnGap: 10,
          },

          { text: ".....................................", style: "center", marginTop: 25 },
          { text: "Thanks for your patronage", style: "center" },
          { text: ".....................................", style: "center" },
        ],

        pageMargins: [15, 40, 15, 40],
        pageSize: { width: 226.8, height: "auto" },

        styles: {
          header: { fontSize: 18 },
          header2: { fontSize: 20 },
          header3: { fontSize: 24 },
          center: { alignment: "center" },
        },
        defaultStyle: {
          font: "vt323",
        },
      };

      pdfMake.createPdf(docDefinition).print();
    },
  },
  created() {
    bus.$on("print-receipt", () => {
      this.build();
    });
  },
};
</script>
